import { Overlay } from "trading-vue-js";

export default {
  name: "PerfectTrades",
  mixins: [Overlay],
  methods: {
    draw(ctx) {
      let layout = this.$props.layout; // Layout object (see API BOOK)
      ctx.lineWidth = 8.5;

      for (var i in this.$props.data) {
        let p = this.$props.data[i];
        ctx.strokeStyle = p[1] ? this.buy_color : this.sell_color;
        // We use previos point here, but the profit should
        // be already in the data (in a real usecase)
        let prev = this.$props.data[i - 1];
        let mydate = new Date(this.$route.params.time);
        // console.log("mydate", mydate.getTime());
        if (
          this.$route.params.time !== "day" &&
          typeof this.$route.params.time !== undefined
        ) {
          //  console.log("params time is there!", p);

          if (mydate.getTime() === p[0]) {
            //   console.log("hit");
            ctx.strokeStyle = p[1] ? "rgba(0,255,0,0.8)" : "rgba(255,0,0,0.8)";
          }
        } else {
          //  console.log("params time is NOT there!", p);
        }
        // ctx.fillStyle = p[1] ? this.buy_color : this.sell_color;
        ctx.beginPath();
        let x = layout.t2screen(p[0]); // x - Mapping
        //  let y = layout.$2screen(p[2]); // y - Mapping
        /*         ctx.arc(x, y, 5.5, 0, Math.PI * 2, true); // Trade point
        ctx.fill(); */

        ctx.stroke();
        ctx.beginPath();

        ctx.moveTo(x, -100000);
        ctx.lineTo(x, 120000);
        //ctx.lineTo(layout.width, y);
        ctx.stroke();

        // If this is a SELL, draw the profit label
        if (p[1] === 0 && prev) {
          /*           let profit = p[2] / prev[2] - 1;
          profit = (profit * 100).toFixed(2) + "%";
          ctx.fillStyle = "#555";
          ctx.font = "16px Arial";
          ctx.textAlign = "center";
          ctx.fillText("XXX", x, y - 25); */
        }
      }
    },
    use_for() {
      return ["PerfectTrades"];
    },
  },
  data() {
    return {};
  },
  computed: {
    sett() {
      // Just an alias
      return this.$props.settings;
    },
    buy_color() {
      return this.sett["buyColor"] || "rgba(0,255,0,0.3)"; // default value
    },
    sell_color() {
      return this.sett["sellColor"] || "rgba(255,0,0,0.3)";
    },
    marker_size() {
      return this.sett["markerSize"] || 5;
    },
    show_label() {
      return this.sett["showLabel"] !== false;
    },
  },
};
