<template>
  <div class="ex2 parent">
    <div class="some-page-wrapper">
      <div class="row">
        <div class="column">
          <div class="blue-column">
            <div
              style="z-index: 9999"
              class="
                absolute
                top-0
                left-0
                inline-flex
                rounded-md
                shadow-sm
                z-50
              "
              role="group"
            >
              <button
                type="button"
                class="
                  py-2
                  px-4
                  text-sm
                  font-medium
                  text-gray-900
                  bg-white
                  rounded-l-lg
                  border border-gray-200
                  hover:bg-gray-100 hover:text-blue-700
                  focus:z-10
                  focus:ring-2
                  focus:ring-blue-700
                  focus:text-blue-700
                  dark:bg-gray-700
                  dark:border-gray-600
                  dark:text-white
                  dark:hover:text-white
                  dark:hover:bg-gray-600
                  dark:focus:ring-blue-500
                  dark:focus:text-white
                "
              >
                Today
              </button>
              <button
                type="button"
                class="
                  py-2
                  px-4
                  text-sm
                  font-medium
                  text-gray-900
                  bg-white
                  border-t border-b border-gray-200
                  hover:bg-gray-100 hover:text-blue-700
                  focus:z-10
                  focus:ring-2
                  focus:ring-blue-700
                  focus:text-blue-700
                  dark:bg-gray-700
                  dark:border-gray-600
                  dark:text-white
                  dark:hover:text-white
                  dark:hover:bg-gray-600
                  dark:focus:ring-blue-500
                  dark:focus:text-white
                "
              >
                Week
              </button>
              <button
                type="button"
                class="
                  py-2
                  px-4
                  text-sm
                  font-medium
                  text-gray-900
                  bg-white
                  border-t border-b border-gray-200
                  hover:bg-gray-100 hover:text-blue-700
                  focus:z-10
                  focus:ring-2
                  focus:ring-blue-700
                  focus:text-blue-700
                  dark:bg-gray-700
                  dark:border-gray-600
                  dark:text-white
                  dark:hover:text-white
                  dark:hover:bg-gray-600
                  dark:focus:ring-blue-500
                  dark:focus:text-white
                "
              >
                Month
              </button>
              <button
                type="button"
                class="
                  py-2
                  px-4
                  text-sm
                  font-medium
                  text-gray-900
                  bg-white
                  rounded-r-md
                  border border-gray-200
                  hover:bg-gray-100 hover:text-blue-700
                  focus:z-10
                  focus:ring-2
                  focus:ring-blue-700
                  focus:text-blue-700
                  dark:bg-gray-700
                  dark:border-gray-600
                  dark:text-white
                  dark:hover:text-white
                  dark:hover:bg-gray-600
                  dark:focus:ring-blue-500
                  dark:focus:text-white
                "
              >
                Year
              </button>
            </div>
            <trading-vue
              :overlays="overlays"
              :data="chartData"
              :width="this.width"
              :height="this.height"
              :toolbar="false"
              :chart-config="{ TB_ICON_BRI: 1.25 }"
              :color-back="colors.colorBack"
              :color-grid="colors.colorGrid"
              :color-text="colors.colorText"
            >
            </trading-vue>
          </div>
        </div>
        <div class="column">
          <div class="green-column bg-white">
            <div class="grid grid-cols-4 gap-4">
              <div class="">
                <h3>Signals / Hour</h3>

                <h1 class="text-3xl">160</h1>
              </div>

              <!-- ... -->
              <div>09</div>
            </div>
            <!-- This example requires Tailwind CSS v2.0+ -->
            hello
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.green {
  background-color: #00ff00;
}
</style>

<script>
import { TradingVue, DataCube } from "trading-vue-js";

import Data from "../../data/data_works.json";
import DataTrades from "../../data/data_trades.json";

/* import Grin from "./Grin.js"; // New import
 */
import PerfectTrades from "./PerfectTrades";

export default {
  name: "app",
  data() {
    return {
      width: window.innerWidth / 2,
      height: 300,

      night: true,

      overlays: [PerfectTrades],
    };
  },
  components: { TradingVue },
  methods: {
    onResize() {
      this.width = window.innerWidth / 2;
      this.height = 300;
    },
  },
  mounted() {
    window.DataCube = this.data;
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    chartData() {
      return new DataCube({
        chart: {
          type: "Candles",
          tf: "1m",
          data: Data.chart.data,
        },
        onchart: [
          {
            name: "Signallog",
            type: "PerfectTrades",
            settings: {
              "z-index": -1,
            },
            data: DataTrades.data,
          },
        ],
        offchart: [],
      });
    },
    colors() {
      return this.night
        ? {}
        : {
            colorBack: "#fff",
            colorGrid: "#eee",
            colorText: "#333",
          };
    },
    username() {
      // We will see what `params` is shortly
      return this.$route.params.symbol;
    },
    alpha_corrected() {
      let obj = this.alphavantage;
      return obj;
    },
  },
};
</script>
