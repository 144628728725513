import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import VueToast from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css";

import App from "./App.vue";
import { createProvider } from "./vue-apollo";
import SignalLogs from "./components/SignalLogs.vue";
import Charts from "./components/charts.vue";
import HomePage from "./components/home.vue";
import MetaBase from "./components/metabase.vue";
import MetaBaseSignallogs from "./components/metabase_signallogs.vue";

import AlertSettings from "./components/alertsettings.vue";
// import CallBack from "./components/callback.vue";
import LoginNew from "./components/login-new.vue";

import HistogramSlider from "vue-histogram-slider";
import "vue-histogram-slider/dist/histogram-slider.css";

Vue.component(HistogramSlider.name, HistogramSlider);
import VueGoodTablePlugin from "vue-good-table";

// import the styles
import "vue-good-table/dist/vue-good-table.css";

Vue.use(VueGoodTablePlugin);
Vue.config.productionTip = false;
/* 
   const parse = function(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      // this.storeValue('test', 'yo', true);
      //storeValue('jwt', JSON.parse(jsonPayload));
      return JSON.parse(jsonPayload);
    
   } */

//  console.log("yo",window.sessionStorage.getItem('auth0') )

const store = new Vuex.Store({
  state: {
    advice: "",

    navigation: [
      {
        name: "HOME / LOGIN",
        path: "/",
      },
      {
        name: "SPECTRE: ALERTSETTINGS",
        path: "/alertsettings",
      },
      {
        name: "SIGNALLOGS",
        path: "/metabase/signallogs",
      },
      {
        name: "Settings",
        path: "/settings",
      },
    ],
    //  user: parse(window.sessionStorage.getItem('auth0')) || false,
    auth0: window.sessionStorage.getItem("auth0") || false,
  },
  mutations: {
    increment(state) {
      state.count++;
    },
  },
});

fetch("https://api.adviceslip.com/advice")
  .then((response) => response.json())
  .then((data) => (store.state.advice = data.slip.advice));

// Install the store instance as a plugin

import "./assets/main.css";
/* import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
Vue.use(VueGoodTablePlugin); */

import VueRouter from "vue-router";

const router = new VueRouter({
  mode: "history",
  routes: [
    // dynamic segments start with a colon
    { path: "/", component: HomePage },
    { path: "/alertsettings", component: AlertSettings },
    { path: "/metabase", component: MetaBase },
    { path: "/metabase/signallogs", component: MetaBaseSignallogs },

    { path: "/login", component: LoginNew },
    /*   { path: "/callback", component: CallBack }, */
    { path: "/charts/", component: Charts },
    {
      path: "/signallogs/:alertname/:timerange/:currency/:time",
      component: SignalLogs,
    },
  ],
});

/* router.beforeEach(async (to) => {
  if (
    // make sure the user is authenticated
    !window.sessionStorage.getItem("auth0") &&
    // ❗️ Avoid an infinite redirect
    to.path !== '/'
  ) {
    // redirect the user to the login page
    return { path: '/' }
  }
}) */
Vue.use(VueRouter);
Vue.use(VueToast);
Vue.use(store);
new Vue({
  apolloProvider: createProvider(),
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
