var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hello"},[_vm._m(0),_c('div',{staticClass:"flex flex-wrap -mx-1 overflow-hidden"},[_c('div',{staticClass:"my-1 px-1 w-1/2 overflow-hidden sm:w-full md:w-1/2"},[_c('vue-good-table',{staticClass:"mt-4",attrs:{"columns":_vm.columns,"rows":_vm.alerts,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 10,
        },"select-options":{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }},on:{"on-selected-rows-change":_vm.selectionChanged}})],1),_c('div',{staticClass:"my-1 px-1 w-1/2 overflow-hidden sm:w-full md:w-1/2"},[_vm._v("yo")])]),_c('p',{staticClass:"text-white"},[_vm._v("Alerts: "+_vm._s(_vm.alerts ? _vm.alerts.length : 0))])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('select',{staticClass:"select w-full max-w-xs bg-gray-300 mt-4 mr-2"},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("Pick a Alert")]),_c('option',[_vm._v("Homer")]),_c('option',[_vm._v("Marge")]),_c('option',[_vm._v("Bart")]),_c('option',[_vm._v("Lisa")]),_c('option',[_vm._v("Maggie")])]),_c('select',{staticClass:"select w-full max-w-xs bg-gray-300 mt-4"},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v("Timeframe")]),_c('option',[_vm._v("1M")]),_c('option',[_vm._v("3M")]),_c('option',[_vm._v("5M")]),_c('option',[_vm._v("15M")])])])}]

export { render, staticRenderFns }