<template>
  <div class="hello">
    metabase

    <iframe
      :src="this.iframeUrl"
      frameborder="0"
      width="800"
      height="600"
      allowtransparency
    ></iframe>
  </div>
</template>

<script>
var sign = require("jwt-encode");

export default {
  name: "MetaBase",
  props: {
    msg: String,
  },
  mounted() {},
  data: () => ({
    METABASE_SITE_URL: "https://metabase.teamgroove.de",
    METABASE_SECRET_KEY:
      "58027c7db6662786a67691f280b73c2b6044ec21cf3f333d51aab99d9e8422d9",
    payload: {
      resource: { dashboard: 2 },
      params: {},
      exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
    },
  }),
  computed: {
    token() {
      return sign(this.payload, this.METABASE_SECRET_KEY);
    },
    iframeUrl() {
      return (
        this.METABASE_SITE_URL +
        "/embed/dashboard/" +
        this.token +
        "#theme=night&bordered=true&titled=true"
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
