<template>
  <div class="hello">
    <div class="mt-4">
      <select class="select w-full max-w-xs bg-gray-300 mt-4 mr-2">
        <option disabled selected>Pick a Alert</option>
        <option>Homer</option>
        <option>Marge</option>
        <option>Bart</option>
        <option>Lisa</option>
        <option>Maggie</option>
      </select>

      <select class="select w-full max-w-xs bg-gray-300 mt-4">
        <option disabled selected>Timeframe</option>
        <option>1M</option>
        <option>3M</option>
        <option>5M</option>
        <option>15M</option>
      </select>
    </div>
    <div class="flex flex-wrap -mx-1 overflow-hidden">
      <div class="my-1 px-1 w-1/2 overflow-hidden sm:w-full md:w-1/2">
        <vue-good-table
          class="mt-4"
          :columns="columns"
          :rows="alerts"
          @on-selected-rows-change="selectionChanged"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 10,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
        />
      </div>
      <div class="my-1 px-1 w-1/2 overflow-hidden sm:w-full md:w-1/2">yo</div>
    </div>

    <p class="text-white">Alerts: {{ alerts ? alerts.length : 0 }}</p>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "AlertSettings",
  data() {
    return {
      columns: [
        {
          label: "Id",
          field: "id",
          type: "number",
        },
        {
          label: "Name",
          field: "name",
        },
      ],
      rows: [
        { id: 1, name: "John", age: 20, createdAt: "", score: 0.03343 },
        {
          id: 2,
          name: "Jane",
          age: 24,
          createdAt: "2011-10-31",
          score: 0.03343,
        },
        {
          id: 3,
          name: "Susan",
          age: 16,
          createdAt: "2011-10-30",
          score: 0.03343,
        },
        {
          id: 4,
          name: "Chris",
          age: 55,
          createdAt: "2011-10-11",
          score: 0.03343,
        },
        {
          id: 5,
          name: "Dan",
          age: 40,
          createdAt: "2011-10-21",
          score: 0.03343,
        },
        {
          id: 6,
          name: "John",
          age: 20,
          createdAt: "2011-10-31",
          score: 0.03343,
        },
      ],
    };
  },
  components: {},
  props: {
    msg: String,
  },

  computed: {
    alerts_enriched() {
      return "hello";
    },
  },
  apollo: {
    alerts: {
      query: gql`
        query SpectreAlerts {
          alerts {
            id
            name
            alert_settings {
              active
              active_custom
              active_demo
              active_real
              id
              invest
              preset_0h0m10s
              preset_0h0m1s
              preset_0h0m2s
              preset_0h0m30s
              preset_0h0m3s
              preset_0h0m4s
              preset_0h0m5s
              preset_0h0m60s
              preset_0h0m6s
              preset_0h0m7s
              preset_0h0m8s
              preset_0h0m9s
              preset_0h1m30s
              preset_0h2m0s
              spectre_hours
              spectre_seconds
              spectre_minutes
              user_id
            }
          }
        }
      `,
      subscribeToMore: {
        document: gql`
          subscription SpectreAlerts {
            alerts {
              id
              name
              alert_settings {
                active
                active_custom
                active_demo
                active_real
                id
                invest
                preset_0h0m10s
                preset_0h0m1s
                preset_0h0m2s
                preset_0h0m30s
                preset_0h0m3s
                preset_0h0m4s
                preset_0h0m5s
                preset_0h0m60s
                preset_0h0m6s
                preset_0h0m7s
                preset_0h0m8s
                preset_0h0m9s
                preset_0h1m30s
                preset_0h2m0s
                spectre_hours
                spectre_seconds
                spectre_minutes
                user_id
              }
            }
          }
        `,
        // Variables passed to the subscription. Since we're using a function,
        // they are reactive
        variables() {},
        // Mutate the previous result
        updateQuery: (previousResult, { subscriptionData }) => {
          // console.log(previousResult, subscriptionData);
          const newResult = {
            alerts: [...previousResult.alerts],
          };
          /*   this.chartData.data.onchart[0].data =
            subscriptionData.data.signal_logs; */
          // console.log("logs", subscriptionData.data.signal_logs);
          // Add the question to the list
          newResult.alerts = subscriptionData.data.alerts;
          return newResult;

          // Here, return the new result from the previous with the new data
        },
      },
      // Reactive parameters
      variables() {
        // Use vue reactive properties here
      },
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
