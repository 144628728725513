<template>
  <!-- Page content here
        v-if="!this.$store.state.auth0"
     -->

  <div class="xcontainer">
    <div class="flex flex-wrap -mx-1 overflow-hidden">
      <div class="my-1 px-1 w-1/3 overflow-hidden sm:w-full md:w-1/3">
        <!-- Column Content -->
        <div class="stats shadow mt-4 mr-2" v-if="this.$store.state.auth0">
          <div class="stat">
            <div class="stat-figure text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="inline-block w-8 h-8 stroke-current"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                ></path>
              </svg>
            </div>
            <div class="stat-title">Total Algos</div>
            <div class="stat-value text-primary">3</div>
            <!--   <div class="stat-desc">21% more than last month</div> -->
          </div>
          <div class="stat">
            <div class="stat-figure text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="inline-block w-8 h-8 stroke-current"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                ></path>
              </svg>
            </div>
            <div class="stat-title">Total Signals</div>
            <div class="stat-value text-primary">72</div>
            <!--   <div class="stat-desc">21% more than last month</div> -->
          </div>
          <!--       <div class="stat">
        <div class="stat-figure text-secondary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="inline-block w-8 h-8 stroke-current"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 10V3L4 14h7v7l9-11h-7z"
            ></path>
          </svg>
        </div>
        <div class="stat-title">Page Views</div>
        <div class="stat-value text-secondary">2.6M</div>
        <div class="stat-desc">21% more than last month</div>
      </div> -->
        </div>
      </div>

      <div class="my-1 px-1 w-1/3 overflow-hidden sm:w-full md:w-1/3">
        <!-- Column Content -->
        <div class="stats shadow mt-4" v-if="this.$store.state.auth0">
          <div class="stat">
            <div class="stat-figure text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="inline-block w-8 h-8 stroke-current"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                ></path>
              </svg>
            </div>
            <div class="stat-title z-0">MY ACTIVE: DEMO</div>
            <div class="stat-value text-primary">23</div>
            <!--   <div class="stat-desc">21% more than last month</div> -->
          </div>
          <div class="stat">
            <div class="stat-figure text-primary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                class="inline-block w-8 h-8 stroke-current"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                ></path>
              </svg>
            </div>
            <div class="stat-title">MY ACTIVE:REAL</div>
            <div class="stat-value text-primary">3</div>
            <!--   <div class="stat-desc">21% more than last month</div> -->
          </div>
          <!--       <div class="stat">
        <div class="stat-figure text-secondary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            class="inline-block w-8 h-8 stroke-current"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 10V3L4 14h7v7l9-11h-7z"
            ></path>
          </svg>
        </div>
        <div class="stat-title">Page Views</div>
        <div class="stat-value text-secondary">2.6M</div>
        <div class="stat-desc">21% more than last month</div>
      </div> -->
        </div>
      </div>

      <div class="my-1 px-1 w-1/3 overflow-hidden sm:w-full md:w-1/3">
        <!-- Column Content -->yo
      </div>

      <div class="my-1 px-1 w-1/3 overflow-hidden sm:w-full md:w-1/3">
        <!-- Column Content -->
        <div
          class="card w-96 bg-base-100 shadow-xl ml-12 mt-2"
          v-if="this.$store.state.auth0"
        >
          <div class="card-body">
            <h2 class="card-title text-white">LIVE: Spectre payouts</h2>
            <!--         <p class="text-gray-400">
          If a dog chews shoes whose shoes does he choose?
        </p> -->
            <div class="overflow-x-auto">
              <table
                class="table table-compact w-full text-white fixed_header"
                ref="spectre_payouts"
              >
                <thead class="text-gray-400">
                  <tr>
                    <th>SYMBOLS/PAIR</th>
                    <th>PERCENT</th>
                  </tr>
                </thead>
                <tbody class="text-gray-300">
                  <tr
                    v-for="asset in spectre_payouts"
                    class="border-b text-gray-400"
                    :key="asset.id"
                  >
                    <th
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-400 text-left"
                    >
                      {{ asset.spectre_asset.asset }}
                    </th>
                    <td
                      class="text-sm text-gray-400 font-light px-6 py-4 whitespace-nowrap text-left"
                    >
                      {{ asset.payout_percent }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="my-1 px-1 w-1/3 overflow-hidden sm:w-full md:w-1/3">
        <!-- Column Content -->
      </div>

      <div class="my-1 px-1 w-1/3 overflow-hidden sm:w-full md:w-1/3">
        <!-- Column Content -->
      </div>
    </div>
    <div class="card w-100 bg-base-100 shadow-xl mt-16 mb-12">
      <div class="card-body">
        <p class="text-lg text-gray-400">
          <span class="text-white">Advice: </span>{{ this.$store.state.advice }}
        </p>
        <div
          v-if="!this.$store.state.auth0"
          class="card-actions justify-center"
        >
          <a class="btn btn-primary text-white" @click="login()"> Login </a>
        </div>
      </div>
    </div>
    <!--       <div class="card w-100 bg-base-400 shadow-xl ml-4">
        <div class="card-body">
          <h2 class="card-title">Card title!</h2>
          <p>If a dog chews shoes whose shoes does he choose?</p>
        </div>
      </div> -->
  </div>

  <!--   <div>auth0: {{ this.$store.state.auth0 }}</div> -->

  <!--     <div v-if="this.$store.state.auth0" class="flex flex-col">
      <h2>Spectre Payouts</h2>
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div class="overflow-hidden">
            <table class="min-w-full">
              <thead class="bg-white border-b">
                <tr>
                  <th
                    scope="col"
                    class="
                      text-sm
                      font-medium
                      text-gray-900
                      px-6
                      py-2
                      text-left
                    "
                  >
                    SYMBOL
                  </th>
                  <th
                    scope="col"
                    class="
                      text-sm
                      font-medium
                      text-gray-900
                      px-6
                      py-2
                      text-left
                    "
                  >
                    PERCENT
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="asset in spectre_payouts"
                  class="bg-gray-100 border-b"
                  :key="asset.id"
                >
                  <td
                    class="
                      px-6
                      py-4
                      whitespace-nowrap
                      text-sm
                      font-medium
                      text-gray-900 text-left
                    "
                  >
                    {{ asset.spectre_asset.asset }}
                  </td>
                  <td
                    class="
                      text-sm text-gray-900
                      font-light
                      px-6
                      py-4
                      whitespace-nowrap
                      text-left
                    "
                  >
                    {{ asset.payout_percent }}%
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> -->
</template>

<script>
import gql from "graphql-tag";
// import { MiniBar } from "minibarjs";
export default {
  apollo: {
    spectre_payouts: {
      query: gql`
        query spectrePayouts {
          spectre_payouts(order_by: { payout_percent: desc }) {
            id
            payout_percent
            spectre_asset {
              asset
            }
          }
        }
      `,
      subscribeToMore: {
        document: gql`
          subscription spectrePayouts {
            spectre_payouts(order_by: { payout_percent: desc }) {
              id
              payout_percent
              spectre_asset {
                asset
              }
            }
          }
        `,
        // Variables passed to the subscription. Since we're using a function,
        // they are reactive
        variables() {},
        // Mutate the previous result
        updateQuery: (previousResult, { subscriptionData }) => {
          // console.log(previousResult, subscriptionData);
          const newResult = {
            spectre_payouts: [...previousResult.spectre_payouts],
          };
          /*   this.chartData.data.onchart[0].data =
            subscriptionData.data.signal_logs; */
          // console.log("logs", subscriptionData.data.signal_logs);
          // Add the question to the list
          newResult.spectre_payouts = subscriptionData.data.spectre_payouts;
          return newResult;

          // Here, return the new result from the previous with the new data
        },
      },
      // Reactive parameters
      variables() {
        // Use vue reactive properties here
      },
    },
  },
  name: "HomePage",
  mounted() {
    // new MiniBar(this.$refs.spectre_payouts);
    this.$toast.open("You did it!");
    console.log(`the component is now mounted.` + this.$route.hash);
    if (this.$route.hash.substring(0, 13) === "#access_token") {
      window.sessionStorage.setItem(
        "auth0",
        this.$route.hash.substring(
          this.$route.hash.indexOf("id_token=") + 9,
          this.$route.hash.length
        )
      );

      window.localStorage.setItem(
        "apollo-token",
        this.$route.hash.substring(
          this.$route.hash.indexOf("id_token=") + 9,
          this.$route.hash.length
        )
      );

      this.$store.state.auth0 = this.$route.hash.substring(
        this.$route.hash.indexOf("id_token=") + 9,
        this.$route.hash.length
      );

      //console.log("hello", this.parse());
      // this.$router.push("/");
      /*       console.log(
        "access_token found",
        this.$route.hash.substring(14, this.$route.hash.length)
      ); */
    }
  },
  data: () => ({
    items: {
      gaga: "gugu",
    },
  }),

  methods: {
    login: () => {
      if (process.env.NODE_ENV !== "production") {
        window.location.assign(
          "https://a00.eu.auth0.com/authorize?client_id=Yc2bktsi1qQKoU6QDS1JiVZW5lh7HDBe&response_type=token%20id_token&redirect_uri=http://172.22.98.165:8080/&scope=openid%20profile&nonce=mynonce"
        );
      } else {
        window.location.assign(
          "https://a00.eu.auth0.com/authorize?client_id=Yc2bktsi1qQKoU6QDS1JiVZW5lh7HDBe&response_type=token%20id_token&redirect_uri=https://charming-pudding-fd886f.netlify.app/&scope=openid%20profile&nonce=mynonce"
        );
      }
    },
    parse: (token) => {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      // this.storeValue('test', 'yo', true);
      //storeValue('jwt', JSON.parse(jsonPayload));
      return JSON.parse(jsonPayload);
    },
  },
  computed: {
    /*     myuser() {
      return this.parse(this.$store.state.auth0);
    }, */
  },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
