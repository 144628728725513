<!-- <template>
  <div>
    yo: {{ this.$route.params.alertname }}

    alertname {{ alertname }}

    {{ signal_logs }}
  </div>
</template> -->
<template>
  <div class="ex2 parent">
    <div class="some-page-wrapper">
      <div class="row">
        <div class="column">
          <div class="blue-column">
            <div
              style="z-index: 9999"
              class="
                absolute
                top-0
                left-0
                inline-flex
                rounded-md
                shadow-sm
                z-50
              "
              role="group"
            >
              <!--               <button
                type="button"
                @click="
                  () => this.$refs.tvjs.setRange(1650479304000, 1650488670000)
                "
                class="
                  py-2
                  px-4
                  text-sm
                  font-medium
                  text-gray-900
                  bg-white
                  rounded-l-lg
                  border border-gray-200
                  hover:bg-gray-100 hover:text-blue-700
                  focus:z-10
                  focus:ring-2
                  focus:ring-blue-700
                  focus:text-blue-700
                  dark:bg-gray-700
                  dark:border-gray-600
                  dark:text-white
                  dark:hover:text-white
                  dark:hover:bg-gray-600
                  dark:focus:ring-blue-500
                  dark:focus:text-white
                "
              >
                setrange
              </button> -->
              <!--  <button
                @click="() => getrange()"
                type="button"
                class="
                  py-2
                  px-4
                  text-sm
                  font-medium
                  text-gray-900
                  bg-white
                  border-t border-b border-gray-200
                  hover:bg-gray-100 hover:text-blue-700
                  focus:z-10
                  focus:ring-2
                  focus:ring-blue-700
                  focus:text-blue-700
                  dark:bg-gray-700
                  dark:border-gray-600
                  dark:text-white
                  dark:hover:text-white
                  dark:hover:bg-gray-600
                  dark:focus:ring-blue-500
                  dark:focus:text-white
                "
              >
                Range
              </button>
              <button
                type="button"
                class="
                  py-2
                  px-4
                  text-sm
                  font-medium
                  text-gray-900
                  bg-white
                  border-t border-b border-gray-200
                  hover:bg-gray-100 hover:text-blue-700
                  focus:z-10
                  focus:ring-2
                  focus:ring-blue-700
                  focus:text-blue-700
                  dark:bg-gray-700
                  dark:border-gray-600
                  dark:text-white
                  dark:hover:text-white
                  dark:hover:bg-gray-600
                  dark:focus:ring-blue-500
                  dark:focus:text-white
                "
              >
                Month
              </button>
              <button
                type="button"
                class="
                  py-2
                  px-4
                  text-sm
                  font-medium
                  text-gray-900
                  bg-white
                  rounded-r-md
                  border border-gray-200
                  hover:bg-gray-100 hover:text-blue-700
                  focus:z-10
                  focus:ring-2
                  focus:ring-blue-700
                  focus:text-blue-700
                  dark:bg-gray-700
                  dark:border-gray-600
                  dark:text-white
                  dark:hover:text-white
                  dark:hover:bg-gray-600
                  dark:focus:ring-blue-500
                  dark:focus:text-white
                "
              >
                Year
              </button> -->
            </div>
            <trading-vue
              :titleTxt="this.$route.params.alertname"
              :overlays="overlays"
              :data="chartdata"
              :width="this.width"
              :height="this.height"
              :timezone="Number(2)"
              :toolbar="true"
              ref="tvjs"
              tf="1m"
              :chart-config="{
                TB_ICON_BRI: 1.25,
              }"
              :color-back="colors.colorBack"
              :color-grid="colors.colorGrid"
              :color-text="colors.colorText"
            >
            </trading-vue>
          </div>
        </div>
        <div class="column">
          <div class="green-column bg-white">
            <!--         <div class="grid grid-cols-4 gap-4">
              <div class="">
                <h3>Signals / Hour</h3>

                <h1 class="text-3xl">160</h1>
              </div>

        
              <div>09</div>
            </div> -->

            <!--             <vue-good-table
              theme="nocturnal"
              :columns="columns"
              :rows="signal_logs"
              :select-options="{ enabled: true }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'top',
                dropdownAllowAll: false,
                //     setCurrentPage: 1,
                jumpFirstOrLast: true,
              }"
            /> -->
            <div class="container">
              <div class="flex items-center justify-center">
                <div class="grid grid-cols-2 gap-0 p-0">
                  <date-time-picker
                    v-model="date"
                    :inline="true"
                    :time-picker="false"
                    @input="forceRerender"
                  ></date-time-picker>

                  <table class="divide-y divide-gray-300 mytable fixed_header">
                    <thead class="bg-gray-800">
                      <tr>
                        <th class="px-2 py-2 text-xs text-gray-500">Time</th>
                        <th class="px-2 py-2 text-xs text-gray-500">
                          Direction
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-gray-900 divide-y divide-gray-300 px-6">
                      <tr
                        v-on:click="clicksignal(item)"
                        v-for="item in signal_logs"
                        :key="item.created_at"
                        class="whitespace-nowrap hover:bg-green-500"
                      >
                        <td class="px-2 py-2 text-sm text-gray-500">
                          {{ item.created_at }}
                        </td>
                        <td class="px-2 py-2 text-sm text-gray-500">
                          {{ item.direction }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!--             <date-time-picker
              :startDate="new Date('2022-04-20T20:51:02.276885+02:00')"
              :singleDate="true"
              @onChange="onDateChange"
            /> -->

            <!--             <bar-chart
              :chartData="this.barChartData"
              :chartOptions="this.barChartOptions"
              height="200"
            ></bar-chart> -->
            <!--   <chart-timeseries></chart-timeseries> -->
            <HistogramSlider
              :key="componentKey"
              style="margin: 10px 40px"
              :width="Number(800)"
              :bar-height="20"
              :data="data"
              :prettify="prettify"
              :drag-interval="true"
              :force-edges="false"
              :colors="['#4facfe', '#00f2fe']"
              :min="this.histogramMin.valueOf()"
              :max="this.histogramMax.valueOf()"
              @finish="histogramChanged"
            />
            <button @click="forceRerender">gaga</button>
            <!-- This example requires Tailwind CSS v2.0+ -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { TradingVue, DataCube } from "trading-vue-js";
import "@lazy-copilot/datetimepicker/dist/datetimepicker.css";
// import { DateTimePicker } from "@lazy-copilot/datetimepicker";
import DateTimePicker from "vue-vanilla-datetime-picker";

/* import BarChart from "./charts/BarChart.vue"; */

// import ChartTimeseries from "./ChartTimeseries.vue";
// import Data from "../../data/data_works.json";
// import DataTrades from "../../data/data_trades.json";

/* import Grin from "./Grin.js"; // New import
 */
import PerfectTrades from "./PerfectTrades";

/* import ApolloExample from './components/ApolloExample.vue'
 */
export default {
  apollo: {
    signal_logs: {
      query: gql`
        query signalLogsBySymbolAlertname(
          $alertname: String!
          $from: timestamptz!
          $to: timestamptz!
        ) {
          signal_logs(
            limit: 100
            order_by: { created_at: asc }
            where: {
              name: { _eq: $alertname }
              created_at: { _gt: $from, _lt: $to }
            }
          ) {
            created_at
            direction
            name
            id
            signal
            source
            symbol
          }
        }
      `,
      subscribeToMore: {
        document: gql`
          subscription signalLogsBySymbolAlertname(
            $alertname: String!
            $from: timestamptz!
            $to: timestamptz!
          ) {
            signal_logs(
              limit: 100
              order_by: { created_at: asc }
              where: {
                name: { _eq: $alertname }
                created_at: { _gt: $from, _lt: $to }
              }
            ) {
              created_at
              direction
              name
              id
              signal
              source
              symbol
            }
          }
        `,
        // Variables passed to the subscription. Since we're using a function,
        // they are reactive
        variables() {
          return {
            alertname: this.alertname,
            from: new Date(this.histogramFrom) || this.histogramMin,
            to: this.histogramMax,
          };
        },
        // Mutate the previous result
        updateQuery: (previousResult, { subscriptionData }) => {
          // console.log(previousResult, subscriptionData);
          const newResult = {
            signal_logs: [...previousResult.signal_logs],
          };
          /*   this.chartData.data.onchart[0].data =
            subscriptionData.data.signal_logs; */
          // console.log("logs", subscriptionData.data.signal_logs);
          // Add the question to the list
          newResult.signal_logs = subscriptionData.data.signal_logs;
          return newResult;

          // Here, return the new result from the previous with the new data
        },
      },
      // Reactive parameters
      variables() {
        // Use vue reactive properties here
        return {
          alertname: this.alertname,
          from: new Date(this.histogramFrom) || this.histogramMin,
          to: this.histogramMax,
        };
      },
    },
    spectre_forexhistory: {
      query: gql`
        query forexHistory(
          $currencypair: String!
          $from: timestamptz!
          $to: timestamptz!
        ) {
          spectre_forexhistory(
            where: {
              currency: { _eq: $currencypair }
              timestamp: { _gt: $from, _lt: $to }
            }
            order_by: { timestamp: asc }
          ) {
            close
            currency
            high
            id
            low
            open
            timestamp
          }
        }
      `,
      subscribeToMore: {
        document: gql`
          subscription forexHistory(
            $currencypair: String!
            $from: timestamptz!
            $to: timestamptz!
          ) {
            spectre_forexhistory(
              where: {
                currency: { _eq: $currencypair }
                timestamp: { _gt: $from, _lt: $to }
              }
              order_by: { timestamp: asc }
            ) {
              close
              currency
              high
              id
              low
              open
              timestamp
            }
          }
        `,
        // Variables passed to the subscription. Since we're using a function,
        // they are reactive
        variables() {
          return {
            from: new Date(this.histogramFrom || this.histogramMin),
            to: this.bufferTo,
            currencypair: this.$route.params.currency,
          };
        },

        // Mutate the previous result
        updateQuery: (previousResult, { subscriptionData }) => {
          // console.log(previousResult, subscriptionData);
          const newResult = {
            spectre_forexhistory: [...previousResult.spectre_forexhistory],
          };
          /*   this.chartData.data.onchart[0].data =
            subscriptionData.data.signal_logs; */
          // console.log("logs", subscriptionData.data.signal_logs);
          // Add the question to the list
          newResult.spectre_forexhistory =
            subscriptionData.data.spectre_forexhistory;
          return newResult;

          // Here, return the new result from the previous with the new data
        },
      },
      // Reactive parameters
      variables() {
        // Use vue reactive properties here
        return {
          from: this.bufferFrom,
          to: this.bufferTo,
          currencypair: this.$route.params.currency,
        };
      },
    },
  },
  name: "App",

  methods: {
    histogramChanged(item) {
      console.log("changed", item);
      this.histogramFrom = item.from;
      this.histogramTo = item.to;

      this.$refs.tvjs.setRange(this.histogramFrom, this.histogramTo);
    },
    forceRerender() {
      this.componentKey += 1;
      this.renderKey += 1;
    },
    clicksignal: function (signal) {
      this.$router.push({
        path:
          "/signallogs/" +
          this.$route.params.alertname +
          "/day/" +
          this.$route.params.currency +
          "/" +
          signal.created_at,
      });
      this.$refs.tvjs.setRange(this.bufferFrom, this.bufferTo);
      console.log(signal);
    },
    onDateChange: function (data) {
      // named route with params to let the router build the url
      /*       this.$router.push({
        path:
          "/signallogs/" +
          this.$route.params.alertname +
          "/day/" +
          this.$route.params.currency +
          "/" +
          data.startDate,
      }); */

      this.selectedDate = data.startDate;
      // console.log("data: ", data.startDate.getTime());
    },
    filldata() {
      let myarray = [];
      for (let i = 3600; i >= 0; i--) {
        myarray.push([
          this.subtractMinutes(i).getTime(),
          4000,
          4005,
          3995,
          4000,
          0,
        ]);
      }
      return myarray;
    },
    subtractMinutes(numOfMinutes, date = new Date("2022-04-21T01:50:10.820")) {
      /*   let t = t + new Date().getTimezoneOffset() * 60000;
      date = new Date(t); */
      date.setMinutes(date.getMinutes() - numOfMinutes);
      return date;
    },
    setrange() {
      this.$refs.tvjs.setRange(1650479304000, 1650488670000);
      // console.log(this.$refs.tvjs.getRange());
    },
    getrange() {
      //  console.log(this.$refs.tvjs.getRange());
    },
    editonchart() {
      //console.log("hello");
      // works adds
      // this.chartData.data.onchart[0].data.push([1551951100000, 1, 4000.0]);
      this.chartData.data.onchart[0].data = this.signalLogs;
      // this.chartData.update("chart.onchart.data", [1551931200000, 0, 4038]);

      // console.log("yo", this.chartData.data.onchart[0].data);

      // this.chartData.update("chart.onchart", [1551931200000, 0, 4038]);
    },
    onResize() {
      this.width = window.innerWidth / 2;
      this.height = 300;
    },
    onSignalAdded(previousResult, { subscriptionData }) {
      // The previous result is immutable
      /*       const newResult = {
        signal_logs: [...previousResult.signal_logs],
      }; */
      // Add the question to the list
      // newResult.signal_logs.push(subscriptionData.data.signal_logs);
      // return newResult;
      return subscriptionData.data.signal_logs;
    },
  },
  components: { TradingVue, DateTimePicker },
  mounted() {
    this.$nextTick(() => {
      // The whole view is rendered, so I can safely access or query
      // the DOM. ¯\_(ツ)_/¯
      setTimeout(this.$refs.tvjs.setRange(1650479304000, 1650488670000), 2000);
    });
    window.DataCube = this.data;
    window.addEventListener("resize", this.onResize);

    const mydate = new Date(this.$route.params.time);
    const day = `${mydate.getDate()}`.padStart(2, "0");
    const month = `${mydate.getMonth() + 1}`.padStart(2, "0");

    const myday = mydate.getFullYear() + "-" + month + "-" + day;

    fetch(
      "http://localhost:3001/api/fetchforex/?day=" +
        myday +
        "&currencypair=" +
        this.$route.params.currency
    );

    //  console.log("data", this.chartData.data.onchart[0].data);
    // this.chartData.data.onchart[0].data = this.signalLogs;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  data: () => ({
    histogramFrom: false,
    histogramTo: false,
    componentKey: 0,
    date: new Date("2022-04-20T20:54:31.65992+02:00"),
    barChartData: {
      datasets: [
        {
          label: "Profit",
          data: [0.1, 0.2, 0.5, -0.2, 0.05],
        },
      ],
      labels: [
        new Date(2020, 1, 1),
        new Date(2020, 1, 10),
        new Date(2020, 1, 12),
        new Date(2020, 2, 2),
        new Date(2020, 4, 1),
      ],
    },
    barChartOptions: {
      tooltips: {},
      scales: {
        xAxes: [
          {
            type: "time",
            // barThickness: 6,
            // distribution: 'series',
            /*
              time: {
                displayFormats: {
                   'minute': 'MMM DD h:mm',
                   'hour': 'MMM DD hA',
                   'day': 'MMM DD',
                   'week': 'MMM DD',
                   'month': 'MMM DD',
                   'quarter': 'MMM DD',
                   'year': 'MMM DD',
                }
              },
              */
            /*
              ticks: {
                callback: (value) => {
                  console.log(value, this.formatDateTime(value))
                  return this.formatDateTime(value)
                },
              }
               */
          },
        ],
      },
    },
    chartData: {
      labels: ["January", "February", "March"],
      datasets: [{ data: [40, 20, 12] }],
    },
    data: [
      "2022-04-20T20:52:00.000Z",
      "2022-04-20T20:53:00.000Z",
      "2022-04-20T20:54:00.000Z",
    ].map((d) => new Date(d)),
    prettify: function (ts) {
      return new Date(ts).toLocaleDateString("de", {
        hour: "numeric",
      });
    },
    selectedDate: false,
    columns: [
      {
        label: "Time",
        field: "created_at",
      },
      {
        label: "Direction",
        field: "direction",
      },
      // ...
    ],
    rows: [
      { id: 1, name: "John", age: 20 },
      { id: 2, name: "Jane", age: 24 },
      // ...
    ],
    items: {},
    signal_logs: [],
    spectre_forexhistory: [],
    width: window.innerWidth / 2,
    height: 300,

    night: true,
    colors() {
      return this.night
        ? {}
        : {
            colorBack: "#fff",
            colorGrid: "#eee",
            colorText: "#333",
          };
    },

    overlays: [PerfectTrades],
  }),

  computed: {
    histogramMin: function () {
      const mydate = new Date(this.date);
      mydate.setHours(0);
      mydate.setMinutes(0);
      mydate.setSeconds(0);
      return mydate;
    },
    histogramMax: function () {
      const mydate = new Date(this.date);
      mydate.setHours(23);
      mydate.setMinutes(59);
      mydate.setSeconds(59);
      return mydate;
    },
    bufferFrom: function () {
      const mydate = new Date(this.$route.params.time);
      mydate.setMinutes(mydate.getMinutes() - 15);
      return mydate;
    },
    bufferTo: function () {
      const mydate = new Date(this.$route.params.time);
      mydate.setMinutes(mydate.getMinutes() + 15);
      return mydate;
    },
    endOfDay: function () {
      const mydate = new Date(this.$route.params.time);
      mydate.setMinutes(0);
      mydate.setHours(0);
      mydate.setDate(mydate.getDate() + 2);
      return mydate;
    },
    chartdata: function () {
      // const now = Date.now();
      return new DataCube({
        chart: {
          type: "Candles",

          data: this.charthistory,
        },
        onchart: [
          {
            name: "Signallog",
            type: "PerfectTrades",
            settings: {
              "z-index": -1,
            },
            data: this.signalLogs,
          },
        ],
        offchart: [],
        /*       datasets: [
        {
          type: "PerfectTrades",
          id: "binance-btcusdt",
          settings: {
            "z-index": -1,
          },
          data: DataTrades.data,
        },
      ], */
      });
    },

    alertname: function () {
      return "" + this.$route.params.alertname;
    },
    timerange: function () {
      return "" + this.$route.params.timerange;
    },
    signalLogs: function () {
      //new Date("2022-04-07T18:10:33.731052+02:00").getTime()

      const data = this.signal_logs.map((item) => [
        new Date(item.created_at).getTime(),
        item.direction === "Call" ? 1 : 0,
        4000,
        // price : item.mrp
      ]);
      return data;

      // return this.signal_logs;
    },
    charthistory: function () {
      //new Date("2022-04-07T18:10:33.731052+02:00").getTime()

      const data = this.spectre_forexhistory.map((item) => [
        new Date(item.timestamp).getTime(),
        item.open,
        item.high,
        item.low,
        item.close,
        0,
        // price : item.mrp
      ]);
      return data;

      // return this.signal_logs;
    },
    signalLogsList: function () {
      //new Date("2022-04-07T18:10:33.731052+02:00").getTime()

      const data = this.signal_logs.map((item) => [
        new Date(item.created_at).toLocaleString(),
        item.direction === "Call" ? "▲" : "▼",
        4000,
        // price : item.mrp
      ]);
      return data;

      // return this.signal_logs;
    },
    signalLogsTable: function () {
      //new Date("2022-04-07T18:10:33.731052+02:00").getTime()

      const data = this.signal_logs.map((item) => [
        new Date(item.created_at).toLocaleString(),
        item.direction === "Call" ? "▲" : "▼",
        4000,
        // price : item.mrp
      ]);
      return data;

      // return this.signal_logs;
    },
  },
  /*     apollo: {
    items: { // Apollo will store query results in this key in the data properties, so it must match a data property of the same name.
      // below is the "initial" query
      query: gql`query signalLogsBySymbolAlertname($alertname: String!) {
        signal_logs(limit: 10, order_by: {created_at: desc}, where: {name: {_eq: $alertname}}) {
            created_at
            direction
            name
            id
            signal
            source
            symbol
        }
        }
     `,
      variables: {
      alertname:  ""
    },
      update(data) { // This function returns our QUERY into the data property "items" when the component is loaded.
        return data.signal_logs;
      },
      subscribeToMore: {
        // below is the subscription query.
        document: gql`
          subscription signalLogs {
            signal_logs(
            limit: 10, order_by: {created_at: desc}) {
            created_at
            direction
            name
            id
            signal
            source
            symbol
          }
          }
        `,
        updateQuery: (previousResult, { subscriptionData }) => {
          return subscriptionData.data; // This returns our SUBSCRIPTION into the data property "items" when there is an update
        }
      }
    }
  } */
};
</script>

<style>
ul {
  list-style: none;
}
li {
  background-color: #fff;
  padding: 8px;
  border-bottom: 1px solid #333;
}
body {
  background-color: #ccc;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.green {
  background-color: #00ff00;
}
.fixed_header tbody {
  display: block;
  overflow: auto;
  height: 240px;
  width: 100%;
}
.fixed_header thead tr {
  display: block;
}
.mytable {
  height: 200px;
}
/* date-picker*/
.date-picker {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  flex-direction: column;
}
.date-picker,
.date-picker__row,
.weekday__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
}
.date-picker__row,
.weekday__row {
  -webkit-box-orient: horizontal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.weekday__row {
  margin-bottom: 10px;
}
.date-picker__cell,
.weekday__cell {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.date-picker__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}
.date-picker__month-header,
.date-picker__year-header {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.date-picker__day {
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.date-picker__day--next,
.date-picker__day--previous {
  opacity: 0.4;
}
.date-picker__day--disabled {
  opacity: 0.1;
}
.date-picker__cell--selected {
  background: #eee;
}
.date-picker__days,
.date-picker__months,
.date-picker__years {
  margin-bottom: 10px;
}
.date-picker__month-button,
.date-picker__year-button {
  width: 100%;
  margin-bottom: 3px;
}
.text-slider__value {
  text-align: center;
}
.time-picker {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.datetime-picker {
  position: relative;
}
.datetime-picker-main {
  position: absolute;
  z-index: 1;
}
.datetime-picker-inline,
.datetime-picker-main {
  width: 320px;
  background: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid grey;
}
.datetime-picker-inline {
  position: relative;
}
.datetime-picker-inline--disabled {
  opacity: 0.7;
}
.datetime-picker-inline--disabled:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.time-picker__button {
  width: 100%;
}
.date-picker__button {
  width: 100%;
  margin-bottom: 10px;
}
.clear__button,
.close__button,
.today__button {
  width: 100%;
  margin-top: 10px;
}
</style>
