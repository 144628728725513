<template>
  <div id="app">
    <div class="hello">
      <div class="drawer">
        <input id="my-drawer-3" type="checkbox" class="drawer-toggle" />

        <div class="drawer-content flex flex-col">
          <!-- Navbar -->
          <div class="w-full navbar bg-base-100 fixed z-10">
            <div class="flex-none lg:hidden">
              <label for="my-drawer-3" class="btn btn-square btn-ghost">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  class="inline-block w-6 h-6 stroke-current"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </label>
            </div>
            <div class="flex-1 px-2 mx-2 text-white">
              <router-link to="/"> BIBI </router-link>
            </div>

            <!-- <div
              v-if="this.$store.state.auth0"
              class="badge badge-lg badge-outline text-gray-400 mr-2"
            >
              DEMO: 100.000,00 $
            </div>
            <div
              v-if="this.$store.state.auth0"
              class="badge badge-lg badge-outline text-gray-400 mr-4"
            >
              REAL: 40,00 $
            </div>
            <div
              v-if="this.$store.state.auth0"
              class="dropdown dropdown-end z-10"
            >
              <label tabindex="0" class="btn bg-error m-1">Panic</label>
              <ul
                tabindex="0"
                class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
              >
                <li>
                  <a class="btn bg-error hover:bg-error hover:text-white"
                    >Deactivate all</a
                  >
                </li>
              </ul>
            </div> -->
            <!--  <div
              v-if="this.$store.state.auth0"
              class="form-control mr-4 text-gray-400"
            >
              <label class="input-group">
                <span>Tagesziel</span>
                <input
                  type="text"
                  placeholder=""
                  class="input input-bordered w-16"
                />
                <span>USD</span>
              </label>
            </div>
 -->
            <div class="flex-none hidden lg:block">
              <router-link
                active-class="tab-active"
                class="tab tab-bordered"
                to="/"
                >HOME / LOGIN</router-link
              >
              <!--   <router-link
                v-if="this.$store.state.auth0"
                active-class="tab-active"
                class="tab tab-bordered"
                to="/alertsettings"
                >SPECTRE: ALERTSETTINGS</router-link
              > -->
              <!-- <div
                v-if="this.$store.state.auth0"
                class="dropdown dropdown-end z-10"
              >
                <label tabindex="0" class="tab tab-bordered m-1"
                  >METABASE</label
                >
                <ul
                  tabindex="0"
                  class="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
                >
                  <li v-if="this.$store.state.auth0" active-class="tab-active">
                    <router-link
                      class="btn text-white"
                      to="/metabase/signallogs"
                      >SIGNALS</router-link
                    >
                  </li>
                </ul>
              </div> -->

              <router-link
                v-if="this.$store.state.auth0"
                active-class="tab-active"
                class="tab tab-bordered"
                to="/settings"
                >SETTINGS</router-link
              >

              >

              <!-- <a href="#/callback" class="tab tab-bordered">CALLBACK</a> -->

              <div class="dropdown dropdown-end bg-base-100">
                <label tabindex="0" class=""
                  ><div class="avatar placeholder">
                    <div
                      class="bg-neutral-focus text-neutral-content rounded-full w-12"
                    >
                      <span>MX</span>
                    </div>
                  </div></label
                >
                <ul
                  tabindex="0"
                  class="dropdown-content menu p-2 shadow bg-base-100 w-52"
                >
                  <li>
                    <router-link
                      active-class="tab-active"
                      class="bg-base text-white"
                      to="/"
                      >HOME / LOGIN</router-link
                    >
                  </li>
                  <li>
                    <div class="btn bg-error hover:bg-error" @click="logout()">
                      LOGOUT
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <keep-alive>
            <div class="mb-0 bg-base-300">
              <router-view class="mt-16" :key="$route.fullPath"></router-view>
            </div>
          </keep-alive>
        </div>
        <div class="drawer-side">
          <label for="my-drawer-3" class="drawer-overlay"></label>
          <ul class="menu p-4 overflow-y-auto w-80 bg-base-100">
            <!-- Sidebar content here -->
            <li><a>Sidebar Item 1</a></li>
            <li><a>Sidebar Item 2</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import gql from "graphql-tag";

/* import ApolloExample from './components/ApolloExample.vue'
 */

export default {
  name: "App",
  methods: {
    logout() {
      console.log("logout");
      window.sessionStorage.clear();
      this.$store.state.auth0 = null;
      this.$router.push("/");
    },
  },
  components: {
    // ApolloExample
  },
  data: () => ({
    items: {},
  }),
};
</script>

<style>
ul {
  list-style: none;
}
li {
  background-color: #fff;
  padding: 8px;
  border-bottom: 1px solid #333;
}
body {
  background-color: #333;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
